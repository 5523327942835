import axios from "axios";
import ApiEndpoint from "./ApiEndpoint";
import {throwErrorToast} from "../components/admin/aaras/NewNominationForProjects";
import {
    setClubsList,
    setClubsListFetchStatus,
    setHomeClubData,
    setHomeClubDataFetchStatus, setSelectedClub, setSelectedClubFetchStatus
} from "../components/redux/slice/clubDataSlice";

export function onBoardNewClub(reqBody, user) {
    const apiEndPoint = new ApiEndpoint();

    let returnableObj = {
        clubId: null,
        clubName: null,
        isLoading: true
    }

    return axios(apiEndPoint.onBoardNewClub(user, reqBody)).then((response) => {
        if (response.status === 201) {
            returnableObj.clubId = response.body.clubId;
            returnableObj.clubName = response.body.clubName;
            returnableObj.isLoading = false;
            return returnableObj;
        }
    }).catch(e => throwErrorToast(e));
}

export function getClubDataById(dispatch, user, clubCode) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setHomeClubDataFetchStatus("pending"));

    return axios(apiEndPoint.getClubDataById(user, clubCode))
        .then(res => {
            if (res.status === 200) {
                if (clubCode === null) {
                    dispatch(setHomeClubData(res.data));
                    dispatch(setHomeClubDataFetchStatus("complete"));
                } else {
                    dispatch(setSelectedClub(res.data));
                    dispatch(setSelectedClubFetchStatus("complete"));
                }
            }
            return res;
        })
        .catch(e => {
            dispatch(setHomeClubDataFetchStatus("complete"));
            throwErrorToast(e);
        });
}

export function getClubsList(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setClubsListFetchStatus("pending"));

    return axios(apiEndPoint.getClubsList(user))
        .then(res => {
            if (res.status === 200) {
                dispatch(setClubsList(res.data));
                dispatch(setClubsListFetchStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setClubsListFetchStatus("complete"));
            throwErrorToast(e);
        });
}