import {createSlice} from '@reduxjs/toolkit';

const initialClubData = {
    clubsList: [],
    homeClub: null,
    selectedClub: null,
    homeClubDataFetchStatus: 'idle',
    selectedClubFetchStatus: 'idle',
    clubsListFetchStatus: 'idle',
}

const clubDataSlice = createSlice({
    name: "clubData",
    initialState: initialClubData,
    reducers: {
        setHomeClubData: (state, action) => {
            state.homeClub = action.payload;
        },
        setClubsList: (state, action) => {
            state.clubsList = action.payload;
        },
        setSelectedClub: (state, action) => {
            state.selectedClub = action.payload;
        },
        setHomeClubDataFetchStatus: (state, action) => {
            state.homeClubDataFetchStatus = action.payload;
        },
        setSelectedClubFetchStatus: (state, action) => {
            state.selectedClubFetchStatus = action.payload;
        },
        setClubsListFetchStatus: (state, action) => {
            state.clubsListFetchStatus = action.payload;
        },
        setAllClubDataNull: (state, action) => {
            return action.payload;
        }
    }
});

export const {
    setHomeClubData,
    setClubsList,
    setSelectedClub,
    setHomeClubDataFetchStatus,
    setSelectedClubFetchStatus,
    setClubsListFetchStatus,
    setAllClubDataNull
} = clubDataSlice.actions;

export const selectHomeClubData = (state) => state.clubData.homeClub;
export const selectClubData = (state) => state.clubData.selectedClub;
export const getHomeClubDataFetchStatus = (state) => state.clubData.homeClubDataFetchStatus;
export const getSelectedClubFetchStatus = (state) => state.clubData.selectedClubFetchStatus;
export const getClubListFetchStatus = (state) => state.clubData.clubsListFetchStatus;
export const selectClubsList = (state) => state.clubData.clubsList;

export default clubDataSlice.reducer;
