import {
    Button,
    Card,
    CardBody, Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    useDisclosure
} from "@nextui-org/react";
import {Finance} from "../../Icons/NavbarIcons";
import {Add, Refresh} from "../../Icons/SystemIcons";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
    RAZOR_PAY_PROD_KEY,
    RAZOR_PAY_TEST_KEY,
    toastOptions
} from "../../../constants/WebPageConstants";
import useRazorpay from "react-razorpay";
import {getFinanceRecords} from "./Finances";
import {getHomeClubDataFetchStatus, selectHomeClubData} from "../../redux/slice/clubDataSlice";
import {addMoneyToClubWallet, createRazorpayOrder, payAdminDues} from "../../../services/FinanceServices";
import {getClubDataById} from "../../../services/ClubServices";

export default function WalletCard() {
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const homeClubData = useSelector(selectHomeClubData);
    const fetchHomeClubDataStatus = useSelector(getHomeClubDataFetchStatus);

    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [value, setValue] = React.useState(null);

    const validateAmount = (value) => value.match(/^[0-9]+$/);

    const isInvalid = React.useMemo(() => {
        if (null === value) return false;
        return !validateAmount(value);
    }, [value]);

    async function triggerRazorpay() {

        let data = await createRazorpayOrder(value, user);

        const options = {
            key: RAZOR_PAY_TEST_KEY, // Enter the Key ID generated from the Dashboard
            amount: value * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "RID 3142",
            order_id: data, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: async function (response) {
                await addMoneyToWallet(response, true)
            },
            prefill: {
                name: user.clubName,
                email: user.emailId,
            },
            notes: {
                address: "Rotaract District 3142",
            },
            theme: {color: "#d41367"},
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", async function (response) {
            await addMoneyToWallet(response.error, false);
            toast.error(<p className="text-medium">Failed to added in wallet</p>, toastOptions)
        });
        rzp1.open();
    }

    function addMoneyToWallet(response, isSuccess) {
        let success, failure;
        if (isSuccess) {
            success = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature
            }
        } else {
            failure = {
                code: response.code,
                description: response.description,
                source: response.source,
                step: response.step,
                reason: response.reason,
                order_id: response.meta.order_id,
                payment_id: response.meta.payment_id
            }
        }

        let reqBody = {
            amount: value,
            transactionType: "Credit",
            successDetails: success,
            failureDetails: failure,
        }

        addMoneyToClubWallet(reqBody, user, dispatch);
    }

    function refreshWallet() {
        getFinanceRecords(dispatch, user);
        getClubDataById(dispatch, user, null);
    }

    return (
        <>
            <ToastContainer/>
            <Card
                isBlurred
                className="border-none bg-background/60 dark:bg-default-100/50 max-w-[750px]"
                shadow="sm">
                <CardBody>
                    <div className="grid grid-cols-6 md:grid-cols-12 gap-4 items-center justify-center">
                        <div className="relative col-span-2 md:col-span-2">
                            <Finance className="text-success" fill="currentColor" size={100}/>
                        </div>

                        <div className="flex flex-col col-span-4 md:col-span-6">
                            <div className="flex flex-col gap-0">
                                <p className="text-small text-foreground/80">Current Home Club Wallet
                                    Balance</p>
                                <h1 className="text-large font-medium mt-2">Rs. {homeClubData?.wallet?.balance}</h1>
                            </div>
                        </div>
                        <div className="flex flex-col col-span-6 md:col-span-4 gap-4">
                            <div className="flex justify-evenly items-center">
                                <Button type="button" color="success" variant="ghost" onPress={onOpen}
                                        startContent={<Add fill="currentColor" size={28}/>}>
                                    Add Money
                                </Button>

                                <Button type="button"
                                        isLoading={fetchHomeClubDataStatus === 'pending'}
                                        isDisabled={fetchHomeClubDataStatus === 'pending'}
                                        isIconOnly color="default" variant="ghost"
                                        onClick={() => refreshWallet()}>
                                    <Refresh fill="currentColor" size={28}/>
                                </Button>
                            </div>
                            {!homeClubData?.wallet?.adminDuesPaid &&
                                <Button type="button" color="warning" variant="flat"
                                        onClick={() => payAdminDues(homeClubData, user, dispatch, fetchHomeClubDataStatus)}>
                                    Pay Admin Dues
                                </Button>}
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Modal
                isOpen={isOpen}
                backdrop="blur"
                isDismissable={false}
                placement="auto"
                onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Add balance</ModalHeader>
                            <ModalBody>
                                <p className="text-small text-default-500">
                                    Please enter the amount that you want to top-up your wallet with.
                                </p>
                                <Input isClearable type="number" isInvalid={isInvalid} onValueChange={setValue}
                                       variant="underlined" label="Amount"
                                       errorMessage={isInvalid && "Please postive and rounded digitis only"}
                                       startContent={
                                           <div className="pointer-events-none flex items-center">
                                               <span className="text-default-400 text-small">₹</span>
                                           </div>
                                       }/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary" isDisabled={!value} onPress={onClose}
                                        onClick={triggerRazorpay}>
                                    Pay now
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}