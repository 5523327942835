import React from "react";
import {council57FormColumns} from "./preferenceOptions";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input,
    Pagination,
    Table, TableBody, TableCell, TableColumn, TableHeader, TableRow
} from "@nextui-org/react";
import {SearchIcon} from "../Icons/SearchIcon";
import {ChevronDownIcon} from "../Icons/ChevronDownIcon";
import {capitalize} from "../admin/Finances/utils";
import {useDispatch, useSelector} from "react-redux";
import {selectAllApplications, setSelectedApplicant} from "../redux/slice/application57Slice";
import {useNavigate} from "react-router-dom";
import {selectFetchStatus} from "../redux/slice/aaraSlice";

export default function TableResponseView() {
    const applications = useSelector(selectAllApplications);
    const isDataLoading = useSelector(selectFetchStatus);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const INITIAL_VISIBLE_COLUMNS = ["applicationId", "timestamp", "basicDetails.name", "rotaractDetails.homeClub", "rotaractDetails.zone", "rotaractDetails.pranaliId", "preferences[0]", "preferences[1]", "preferences[2]"];

    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    // const [preference1Filter, setPreference1Filter] = React.useState("all");
    // const [preference2Filter, setPreference2Filter] = React.useState("all");
    // const [preference3Filter, setPreference3Filter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "applicationId",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return council57FormColumns;

        return council57FormColumns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredCouncil57Applications = [...applications];

        if (hasSearchFilter) {
            filteredCouncil57Applications = filteredCouncil57Applications.filter((records) =>
                records.basicDetails.name.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        // if (preference1Filter !== "all" && Array.from(preference1Filter).length !== preferenceOptions.length) {
        //     filteredCouncil57Applications = filteredCouncil57Applications.filter((records) =>
        //         Array.from(preference1Filter).includes(records.preferences[0]),
        //     );
        // }
        // if (preference2Filter !== "all" && Array.from(preference2Filter).length !== preferenceOptions.length) {
        //     filteredCouncil57Applications = filteredCouncil57Applications.filter((records) =>
        //         Array.from(preference2Filter).includes(records.preferences[0]),
        //     );
        // }
        // if (preference3Filter !== "all" && Array.from(preference3Filter).length !== preferenceOptions.length) {
        //     filteredCouncil57Applications = filteredCouncil57Applications.filter((records) =>
        //         Array.from(preference3Filter).includes(records.preferences[0]),
        //     );
        // }

        return filteredCouncil57Applications;
    }, [applications, filterValue]);
    // }, [applications, filterValue, preference1Filter, preference2Filter, preference3Filter]);


    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((record, columnKey) => {
        const keyArray = columnKey.split(".");
        let cellValue;
        keyArray.length > 1
            ? cellValue = record[keyArray[0]][keyArray[1]]
            : cellValue = record[columnKey];

        switch (columnKey) {
            case "timestamp":
                return (
                    <p className="text-bold text-small capitalize">{
                        new Date(record.timestamp.replaceAll("T", " ")).toLocaleString()}</p>
                );
            case "preferences[0]":
                return (
                    <p>{record["preferences"][0]}</p>
                );
            case "preferences[1]":
                return (
                    <p>{record["preferences"][1]}</p>
                );
            case "preferences[2]":
                return (
                    <p>{record["preferences"][2]}</p>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, [])

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        className="w-full sm:max-w-[44%]"
                        placeholder="Search by name..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        {/* <Dropdown>
                        <DropdownTrigger className="hidden sm:flex">
                            <Button endContent={<ChevronDownIcon className="text-small"/>} color="primary"
                                    variant="ghost">
                                Preference 1
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            disallowEmptySelection
                            aria-label="Preference 1 Columns"
                            closeOnSelect={false}
                            selectedKeys={preference1Filter}
                            selectionMode="multiple"
                            onSelectionChange={setPreference1Filter}>
                            {preferenceOptions.map((types) => (
                                <DropdownItem key={types.uid} className="capitalize">
                                    {capitalize(types.name)}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} color="primary"
                                        variant="ghost">
                                    Preference 2
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Preference 1 Columns"
                                closeOnSelect={false}
                                selectedKeys={preference2Filter}
                                selectionMode="multiple"
                                onSelectionChange={setPreference2Filter}>
                                {preferenceOptions.map((types) => (
                                    <DropdownItem key={types.uid} className="capitalize">
                                        {capitalize(types.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} color="primary"
                                        variant="ghost">
                                    Preference 3
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Preference 1 Columns"
                                closeOnSelect={false}
                                selectedKeys={preference3Filter}
                                selectionMode="multiple"
                                onSelectionChange={setPreference3Filter}>
                                {preferenceOptions.map((types) => (
                                    <DropdownItem key={types.uid} className="capitalize">
                                        {capitalize(types.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>*/}
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {council57FormColumns.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Total {applications.length} records</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        // preference1Filter,
        // preference2Filter,
        // preference3Filter,
        visibleColumns,
        onRowsPerPageChange,
        applications.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Previous
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <Table
            aria-label="Example table with custom cells, pagination and sorting"
            isHeaderSticky
            bottomContent={bottomContent}
            bottomContentPlacement="outside"
            classNames={{
                wrapper: "max-h-[582px]",
            }}
            selectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            topContent={topContent}
            topContentPlacement="outside"
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}
            onRowAction={key => {
                dispatch(setSelectedApplicant(sortedItems[sortedItems.findIndex(x => x.applicationId === key)]));
                navigate("/pranali/council58-applications/view");
            }}>

            <TableHeader columns={headerColumns}>
                {(column) => (
                    <TableColumn
                        key={column.uid}
                        align={column.uid === "actions" ? "center" : "start"}
                        allowsSorting={column.sortable}>
                        {column.name}
                    </TableColumn>
                )}
            </TableHeader>
            <TableBody emptyContent={"No record found"} items={sortedItems} isLoading={isDataLoading !== "complete"}>
                {item => <TableRow key={item.applicationId}>
                    {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>}
            </TableBody>
        </Table>
    );
}