import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    DD_AVENUES_LIST,
    DD_PROJECT_MODE,
    DD_SOCIAL_MEDIA_PLATFORMS, DD_THRUST_AREA
} from "../../../constants/dropdownList";
import {
    Autocomplete,
    AutocompleteItem, Avatar,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox, DatePicker,
    Input,
    Select,
    SelectItem
} from "@nextui-org/react";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {getClubListFetchStatus, selectClubsList} from "../../redux/slice/clubDataSlice";
import {
    getDistrictCouncilMembersDataStatus,
    getMembershipDataStatus,
    selectDistrictCouncilMembersData,
    selectMembershipData
} from "../../redux/slice/membershipSlice";
import {
    getNewReportStatus,
    getSelectedReportStatus,
    selectSelectedReport,
    setSelectedReport, setSelectedReportStatus
} from "../../redux/slice/reportingSlice";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {applicationRTESection} from "../../council57/ApplicationRTESection";

import {
    getDistrictCouncilMembersData, getMembershipData,
} from "../../../services/MembershipService";
import {now, getLocalTimeZone, parseAbsoluteToLocal} from "@internationalized/date";
import {Add, Minus} from "../../Icons/SystemIcons";
import {addProjectReport, updateProjectReport} from "../../../services/ProjectsServices";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";
import {Icon} from "@iconify/react";

export default function ReportProject() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);

    const clubsList = useSelector(selectClubsList);
    const fetchClubListStatus = useSelector(getClubListFetchStatus);

    const activeMembersList = useSelector(selectMembershipData).filter(members => members.membershipStatus === "Active");
    const guestMembersList = useSelector(selectMembershipData).filter(members => members.membershipStatus === "Guest");
    const membershipStatus = useSelector(getMembershipDataStatus);

    const dcmDataList = useSelector(selectDistrictCouncilMembersData);
    const dcmDataStatus = useSelector(getDistrictCouncilMembersDataStatus);

    const editReport = useSelector(selectSelectedReport);
    const editedReportStatus = useSelector(getSelectedReportStatus);
    const selectedRecordType = useSelector(getSelectedReportStatus);
    const newReportStatus = useSelector(getNewReportStatus);

    const [projectName, setProjectName] = useState(editReport?.name ? editReport.name : "");
    const [venue, setVenue] = useState(editReport?.venue ? editReport.venue : "");
    const [projectMode, setProjectMode] = useState(editReport?.projectMode ? new Set([editReport.projectMode]) : new Set([]));
    const [startDate, setStartDate] = useState(editReport?.startDate ? parseAbsoluteToLocal(new Date(editReport.startDate.replace("T", " ")).toISOString()) : now(getLocalTimeZone()));
    const [endDate, setEndDate] = useState(editReport?.endDate ? parseAbsoluteToLocal(new Date(editReport.endDate.replace("T", " ")).toISOString()) : now(getLocalTimeZone()));
    const [thrustAreaType, setThrustAreaType] = useState(editReport?.thrustAreaType ? new Set().add(editReport.thrustAreaType) : new Set([]));
    const [avenueMain, setAvenueMain] = useState(editReport?.avenueMain ? new Set().add(editReport.avenueMain) : new Set([]));
    const [avenueOptional, setAvenueOptional] = useState(editReport?.avenueOptional ? new Set().add(editReport.avenueOptional) : new Set([]));
    const [isJointProject, setIsJointProject] = useState(editReport?.isJointProject ? editReport.isJointProject : false);
    const [isInstallation, setIsInstallation] = useState(editReport?.isInstallation ? editReport.isInstallation : false);
    const [isFlagShipProject, setIsFlagShipProject] = useState(editReport?.isFlagShipProject ? editReport.isFlagShipProject : false);
    const [isDraftReport, setIsDraftReport] = useState(editReport?.isDraft ? editReport.isDraft : false);

    const sizeLimit = 1000;
    const [prjDescAim, setPrjDescAim] = useState(editReport?.projectDescription?.aim ? editReport.projectDescription.aim : "");
    const [prjDescGroundWork, setPrjDescGroundWork] = useState(editReport?.projectDescription?.groundWork ? editReport.projectDescription.groundWork : "");
    const [prjDescSummary, setPrjDescSummary] = useState(editReport?.projectDescription?.summary ? editReport.projectDescription.summary : "");
    const [prjDescFeedback, setPrjDescFeedback] = useState(editReport?.projectDescription?.feedBacks
        ? [...editReport.projectDescription.feedBacks]
        : [{name: "", message: ""}]
    );

    const addFieldsForFeedbacks = () => {
        setPrjDescFeedback([...prjDescFeedback, {name: "", message: ""}])
    }

    const removeFormFieldsForFeedbacks = (i) => {
        let updatedValues = prjDescFeedback;
        updatedValues.splice(i, 1);
        setPrjDescFeedback([...updatedValues]);
    }

    const addFeedbackProvider = (i, e) => {
        let newFormValues = [...prjDescFeedback];
        newFormValues[i].name = e.target.value;
        setPrjDescFeedback(newFormValues);
    }

    const addFeedbackMessage = (i, e) => {
        let newFormValues = [...prjDescFeedback];
        newFormValues[i].message = e.target.value;
        setPrjDescFeedback(newFormValues);
    }

    const [income, setIncome] = useState(editReport?.eventFinances?.income ? editReport.eventFinances.income : 0);
    const [expense, setExpense] = useState(editReport?.eventFinances?.expense ? editReport.eventFinances.expense : 0);

    const [jointEventIncome, setJointEventIncome] = useState(editReport?.eventFinances?.jointEventFinances?.income ? editReport.eventFinances.jointEventFinances.income : 0);
    const [jointEventExpense, setJointEventExpense] = useState(editReport?.eventFinances?.jointEventFinances?.expense ? editReport.eventFinances.jointEventFinances.expense : 0);

    const [chairPersons, setChairPersons] = useState(editReport?.chairPersons ? new Set(editReport.chairPersons) : new Set([]));

    const [activeAttendees, setActiveAttendees] = useState(editReport?.attendance?.homeClubActiveMembers ? new Set(editReport.attendance.homeClubActiveMembers.map(String)) : new Set([]));
    const [guestAttendees, setGuestAttendees] = useState(editReport?.attendance?.homeClubGuestMembers ? new Set(editReport.attendance.homeClubGuestMembers.map(String)) : new Set([]));
    const [dcmAttendees, setDcmAttendees] = useState(editReport?.attendance?.dcms ? new Set(editReport.attendance.dcms.map(String)) : new Set([]));
    const [rotariansCount, setRotariansCount] = useState(editReport?.attendance?.rotarians ? editReport.attendance.rotarians : 0);
    const [otherGuestsCount, setOtherGuestsCount] = useState(editReport?.attendance?.otherGuests ? editReport.attendance.otherGuests : 0);
    const [alumnusCount, setAlumnusCount] = useState(editReport?.attendance?.alumnus ? editReport.attendance.alumnus : 0);
    const [otherClubMembersCount, setOtherClubMembersCount] = useState(editReport?.attendance?.otherClubMembers ? editReport.attendance.otherClubMembers : 0);
    const [otherPisCount, setOtherPisCount] = useState(editReport?.attendance?.otherPis ? editReport.attendance.otherPis : 0);
    const [otherDistrictRotaractorsCount, setOtherDistrictRotaractorsCount] = useState(editReport?.attendance?.otherDistrictRotaractors ? editReport.attendance.otherDistrictRotaractors : 0);
    const [interactorsCount, setInteractorsCount] = useState(editReport?.attendance?.interactors ? editReport.attendance.interactors : 0);

    const [coverImageURL, setCoverImageURL] = useState(editReport?.supportDocuments?.coverImageURL ? editReport.supportDocuments.coverImageURL : "");
    const [supportDocumentsURL, setSupportDocumentsURL] = useState(editReport?.supportDocuments?.supportDocumentsURL ? editReport.supportDocuments.supportDocumentsURL : "");
    const [socialMediaLinks, setSocialMediaLinks] = useState(editReport?.supportDocuments?.socialMediaLinks
        ? [...editReport.supportDocuments.socialMediaLinks]
        : [{platformName: "", postURL: ""}]
    );

    const addFieldsForSocialMediaLinks = () => {
        setSocialMediaLinks([...socialMediaLinks, {platformName: "", postURL: ""}])
    }

    const removeFormFieldsForSocialMediaLinks = (i) => {
        let updatedValues = socialMediaLinks;
        updatedValues.splice(i, 1);
        setSocialMediaLinks([...updatedValues]);
    }

    const addPlatformName = (i, e) => {
        let newFormValues = [...socialMediaLinks];
        newFormValues[i].platformName = e.target.value;
        setSocialMediaLinks(newFormValues);
    }

    const addPlatformUrl = (i, e) => {
        let newFormValues = [...socialMediaLinks];
        newFormValues[i].postURL = e.target.value;
        setSocialMediaLinks(newFormValues);
    }

    const [jointProjectClubs, setJointProjectClubs] = useState(editReport?.jointEventClubs ? new Set(editReport.jointEventClubs.map(String)) : new Set([]));


    useEffect(() => {
        if (window.location.pathname.toString().includes("/edit-report") && (null === editReport || undefined === editReport))
            navigate("..");

        if (membershipStatus === "idle")
            getMembershipData(dispatch, user);

        if (dcmDataStatus !== "pending")
            getDistrictCouncilMembersData(dispatch, user);
    }, []);

    const validateCoverImageUrl = (value) => value.includes("https://drive.google.com/file/d/") || value.includes("https://drive.google.com/uc?export=view&id=");

    const isInvalidCoverImage = React.useMemo(() => {
        if (coverImageURL === "") return false;

        return !validateCoverImageUrl(coverImageURL);
    }, [coverImageURL]);

    const handleUpdateForAim = (value, editor) => {
        setPrjDescAim(value);
    };

    const handleUpdateForGW = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= sizeLimit) {
            setPrjDescGroundWork(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 1000 words. Please refrain from adding more. Groundwork data will be cleared due to this action.');
            setPrjDescGroundWork('');
        }
    };

    const handleUpdateForSummary = (value, editor) => {
        const length = editor.plugins.wordcount.getCount();

        if (length <= sizeLimit) {
            setPrjDescSummary(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 1000 words. Please refrain from adding more. Summary data will be cleared due to this action.');
            setPrjDescSummary('');
        }
    };

    const handleBeforeAddUndo = (evt, editor) => {
        const length = editor.plugins.wordcount.getCount();
        if (length > sizeLimit) {
            evt.preventDefault();
        }
    };

    function checkIsProjectEdit() {
        if (window.location.pathname.toString().includes("/edit-report"))
            return (undefined !== projectMode && projectMode.keys().next().value === "Online");
        else
            return (undefined !== projectMode && projectMode.keys().next().value === "Online")
    }

    function clearTaskAndCloseProjectsScreen() {
        dispatch(setSelectedReport({
            report: null,
            reportType: "Project"
        }));
        dispatch(setSelectedReportStatus('idle'));
        navigate("..");
    }

    async function submitForm(e) {
        e.preventDefault();

        let attendanceObjValue = {
            homeClubActiveMembers: [...activeAttendees].map(Number),
            homeClubGuestMembers: [...guestAttendees].map(Number),
            dcms: [...dcmAttendees].map(Number),
            rotarians: Number(rotariansCount),
            otherGuests: Number(otherGuestsCount),
            alumnus: Number(alumnusCount),
            otherClubMembers: Number(otherClubMembersCount),
            otherPis: Number(otherPisCount),
            otherDistrictRotaractors: Number(otherDistrictRotaractorsCount),
            interactors: Number(interactorsCount),
        };

        let supportDocumentsObjValue = {
            coverImageURL: coverImageURL,
            supportDocumentsURL: supportDocumentsURL.trim(),
        };

        let requestBody = {
            name: projectName.trim(),
            venue: venue.trim(),
            projectMode: projectMode.keys().next().value,
            startDate: startDate.toString().split(".")[0].split("+")[0],
            endDate: endDate.toString().split(".")[0].split("+")[0],
            thrustAreaType: thrustAreaType.keys().next().value,
            avenueMain: avenueMain.keys().next().value,
            avenueOptional: avenueOptional.keys().next().value,
            isJointProject: isJointProject,
            isInstallation: isInstallation,
            isFlagShipProject: isFlagShipProject,
            isDraft: isDraftReport,
            projectDescription: {
                aim: prjDescAim,
                groundWork: prjDescGroundWork,
                summary: prjDescSummary,
                feedBacks: prjDescFeedback
            },
            eventFinances: {
                income: Number(income),
                expense: Number(expense),
                profit: Number(income) >= Number(expense) ? Number(income) - Number(expense) : 0,
                loss: Number(income) < Number(expense) ? Number(expense) - Number(income) : 0,
            },
            chairPersons: [...chairPersons].map(Number),
            attendance: attendanceObjValue,
            supportDocuments: supportDocumentsObjValue,
        }

        if (isJointProject) {
            requestBody.eventFinances["jointEventFinances"] = {
                income: Number(jointEventIncome),
                expense: Number(jointEventExpense),
                profit: Number(jointEventIncome) >= Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0,
                loss: Number(jointEventIncome) < Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0,
            }
            requestBody["jointEventClubs"] = [...jointProjectClubs];
        }

        if (null === editReport && undefined === editReport?.projectId)
            addProjectReport(dispatch, user, requestBody, navigate);
        else
            updateProjectReport(dispatch, user, requestBody, editReport.projectId, navigate)
    }

    return (
        <Card className="mt-10 p-4">
            <ToastContainer/>
            <form onSubmit={submitForm}>
                <CardHeader className="text-xl font-medium">
                    {null !== editReport
                        ? "Edit Project Report : " + editReport?.projectId
                        : " New Project Report"
                    }
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                        <Input
                            isRequired
                            type="text"
                            variant="underlined"
                            label="Name"
                            className="max-w-md"
                            value={projectName}
                            onValueChange={setProjectName}
                        />
                        <Input
                            isRequired
                            type="text"
                            variant="underlined"
                            label="Venue"
                            className="max-w-md"
                            value={venue}
                            onValueChange={setVenue}
                        />
                        <Select
                            isRequired
                            items={DD_PROJECT_MODE}
                            label="Project Mode"
                            variant="underlined"
                            className="max-w-md"
                            selectedKeys={projectMode}
                            onSelectionChange={setProjectMode}>
                            {prjModeListItems =>
                                <SelectItem key={prjModeListItems.value} value={prjModeListItems.value}>
                                    {prjModeListItems.label}
                                </SelectItem>
                            }
                        </Select>
                        <Select
                            isRequired
                            items={DD_THRUST_AREA}
                            label="Does this project fall under any of the District thrust areas?"
                            variant="underlined"
                            className="max-w-md"
                            selectedKeys={thrustAreaType}
                            onSelectionChange={setThrustAreaType}>
                            {thrustAreaListItems =>
                                <SelectItem key={thrustAreaListItems.value} value={thrustAreaListItems.value}>
                                    {thrustAreaListItems.label}
                                </SelectItem>
                            }
                        </Select>
                        <DatePicker
                            label="Start Date & Time"
                            description={"Date & Time Format : mm/dd/yyyy - hh:mm."}
                            className="max-w-md"
                            variant="underlined"
                            hideTimeZone
                            value={startDate}
                            onChange={setStartDate}
                            maxValue={now(getLocalTimeZone())}
                            showMonthAndYearPickers
                        />
                        <DatePicker
                            label="End Date & Time"
                            description={"Date & Time Format : mm/dd/yyyy - hh:mm."}
                            className="max-w-md"
                            variant="underlined"
                            hideTimeZone
                            value={endDate}
                            onChange={setEndDate}
                            maxValue={now(getLocalTimeZone())}
                            showMonthAndYearPickers
                        />
                        <Select
                            isRequired
                            items={DD_AVENUES_LIST}
                            label="Avenue 1"
                            variant="underlined"
                            className="max-w-md"
                            selectedKeys={avenueMain}
                            onSelectionChange={setAvenueMain}>
                            {prjAvenueMainListItems => prjAvenueMainListItems.label !== "Joint Project" &&
                                <SelectItem key={prjAvenueMainListItems.label} value={prjAvenueMainListItems.value}>
                                    {prjAvenueMainListItems.label}
                                </SelectItem>
                            }
                        </Select>
                        <Select
                            items={DD_AVENUES_LIST}
                            label="Avenue 2"
                            variant="underlined"
                            className="max-w-md"
                            selectedKeys={avenueOptional}
                            onSelectionChange={setAvenueOptional}>
                            {prjAvenueOptionalListItems => (prjAvenueOptionalListItems.label !== "Joint Project" || avenueMain.keys().next().value === prjAvenueOptionalListItems.label) &&
                                <SelectItem key={prjAvenueOptionalListItems.value}
                                            value={prjAvenueOptionalListItems.value}>
                                    {prjAvenueOptionalListItems.label}
                                </SelectItem>
                            }
                        </Select>

                        <Checkbox className="mt-2 md:max-2xl:mt-4" isSelected={isDraftReport}
                                  onValueChange={setIsDraftReport}>
                            Save report as Draft?
                        </Checkbox>
                        <Checkbox className="mt-2 md:max-2xl:mt-4" isSelected={isInstallation}
                                  onValueChange={setIsInstallation}>
                            Is this Project an Installation?
                        </Checkbox>
                        <Checkbox className="mt-2" isSelected={isFlagShipProject} onValueChange={setIsFlagShipProject}>
                            Is it a Flagship Project?
                        </Checkbox>
                        <Checkbox className="mt-2" isSelected={isJointProject} onValueChange={setIsJointProject}>
                            Is it a Joint Project?
                        </Checkbox>
                    </div>
                    {applicationRTESection(prjDescAim,
                        handleUpdateForAim,
                        null,
                        "Project Aim",
                        true,
                        null)}
                    {applicationRTESection(prjDescGroundWork,
                        handleUpdateForGW,
                        handleBeforeAddUndo,
                        "Project Groundwork",
                        true,
                        null)}
                    {applicationRTESection(prjDescSummary,
                        handleUpdateForSummary,
                        handleBeforeAddUndo,
                        "Project Summary",
                        true,
                        null)}

                    <>
                        <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Project Feedback </h2>
                        {prjDescFeedback.map((feedback, index) => (
                            <div className="grid sm:grid-cols-12 gap-4 items-center">
                                <Input
                                    type="text"
                                    variant="underlined"
                                    label="Feedback Given By"
                                    value={feedback.name}
                                    onChange={e => addFeedbackProvider(index, e)}
                                    className="max-w-md sm:col-span-3"
                                />
                                <Input
                                    type="text"
                                    variant="underlined"
                                    label="Feedback Message"
                                    value={feedback.message}
                                    onChange={e => addFeedbackMessage(index, e)}
                                    className="sm:col-span-8"
                                />
                                {prjDescFeedback.length > 1
                                    ? <Button isIconOnlytype="button" color="danger" variant="flat"
                                              className="w-auto col-span-1 sm:col-span-1 md:order-3"
                                              onClick={() => removeFormFieldsForFeedbacks(index)}>
                                        <Minus fill="currentColor"
                                               size={28}/>
                                    </Button>
                                    : null}
                            </div>
                        ))}
                        <div className="mt-2 w-full flex justify-center">
                            <Button className="max-w-sm" color="primary" variant="ghost" startContent={
                                <Add fill="currentColor" size={28}/>
                            } onClick={addFieldsForFeedbacks}>
                                Add more
                            </Button>
                        </div>
                    </>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
                        <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;
                            {isJointProject ? "Home Club Project Finance" : "Project Finance"}</h2>
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Income"
                            value={income}
                            onValueChange={setIncome}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Expense"
                            value={expense}
                            onValueChange={setExpense}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isDisabled
                            type="number"
                            variant="underlined"
                            label="Profit"
                            value={Number(income) >= Number(expense) ? Number(income) - Number(expense) : 0}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isDisabled
                            type="number"
                            variant="underlined"
                            label="Loss"
                            value={Number(income) < Number(expense) ? Number(expense) - Number(income) : 0}
                            placeholder="0"
                            className="max-w-md"
                        />
                    </div>
                    {isJointProject &&
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
                            <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Overall Joint Project
                                Finance</h2>
                            <Input
                                isRequired
                                type="number"
                                variant="underlined"
                                label="Total Income"
                                value={jointEventIncome}
                                onValueChange={setJointEventIncome}
                                placeholder="0"
                                className="max-w-md"
                            />
                            <Input
                                isRequired
                                type="number"
                                variant="underlined"
                                label="Total Expense"
                                value={jointEventExpense}
                                onValueChange={setJointEventExpense}
                                placeholder="0"
                                className="max-w-md"
                            />
                            <Input
                                isDisabled
                                type="number"
                                variant="underlined"
                                label="Total Profit"
                                value={Number(jointEventIncome) >= Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0}
                                placeholder="0"
                                className="max-w-md"
                            />
                            <Input
                                isDisabled
                                type="number"
                                variant="underlined"
                                label="Total Loss"
                                value={Number(jointEventIncome) < Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0}
                                placeholder="0"
                                className="max-w-md"
                            />
                        </div>
                    }
                    <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Project Chair Person(s)</h2>
                    <Select
                        isRequired
                        selectionMode="multiple"
                        items={activeMembersList}
                        isLoading={membershipStatus === "pending"}
                        isDisabled={activeMembersList.length === 0}
                        label="Project Chair's"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={chairPersons}
                        onSelectionChange={setChairPersons}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>

                    <Autocomplete
                        aria-label="Select you chairpersons"
                        classNames={{
                            base: "max-w-xs",
                            listboxWrapper: "max-h-[320px]",
                            selectorButton: "text-default-500",
                        }}
                        defaultItems={activeMembersList}
                        inputProps={{
                            classNames: {
                                input: "ml-1",
                                inputWrapper: "h-[48px]",
                            },
                        }}
                        listboxProps={{
                            hideSelectedIcon: true,
                            itemClasses: {
                                base: [
                                    "rounded-medium",
                                    "text-default-500",
                                    "transition-opacity",
                                    "data-[hover=true]:text-foreground",
                                    "dark:data-[hover=true]:bg-default-50",
                                    "data-[pressed=true]:opacity-70",
                                    "data-[hover=true]:bg-default-200",
                                    "data-[selectable=true]:focus:bg-default-100",
                                    "data-[focus-visible=true]:ring-default-500",
                                ],
                            },
                        }}
                        placeholder="Select you chairpersons"
                        popoverProps={{
                            offset: 10,
                            classNames: {
                                base: "rounded-large",
                                content: "p-1 border-small border-default-100 bg-background",
                            },
                        }}
                        radius="full"
                        startContent={<Icon icon="line-md:search" width="24" height="24"/>}
                        variant="bordered"
                    >
                        {(members) => (
                            <AutocompleteItem key={members["membershipId"]} textValue={members["memberName"]}>
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-2 items-center">
                                        <Avatar alt={members["memberName"]} className="flex-shrink-0" size="sm"
                                                src={members["profilePhotoUrl"]}/>
                                        <div className="flex flex-col">
                                            <span className="text-small">{members["memberName"]}</span>
                                            <span className="text-tiny text-default-400">{members["membershipId"]}</span>
                                        </div>
                                    </div>
                                    <Button className="border-small mr-0.5 font-medium shadow-small" radius="full"
                                            size="sm" variant="bordered">
                                        Add
                                    </Button>
                                </div>
                            </AutocompleteItem>
                        )}
                    </Autocomplete>

                    <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Attendance</h2>
                    <Select
                        isRequired
                        selectionMode="multiple"
                        items={activeMembersList}
                        isLoading={membershipStatus === "pending"}
                        isDisabled={activeMembersList.length === 0}
                        label="Active Home Club Members"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={activeAttendees}
                        onSelectionChange={setActiveAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                    <Select
                        selectionMode="multiple"
                        items={guestMembersList}
                        isLoading={membershipStatus === "pending"}
                        isDisabled={guestMembersList.length === 0}
                        label="Guest Home Club Members"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={guestAttendees}
                        onSelectionChange={setGuestAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                    <Select
                        selectionMode="multiple"
                        items={dcmDataList}
                        isLoading={dcmDataStatus === "pending"}
                        isDisabled={dcmDataList.length === 0}
                        label="District Council Members"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={[...dcmAttendees]}
                        onSelectionChange={setDcmAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Rotarians"
                            value={rotariansCount}
                            onValueChange={setRotariansCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Alumnus"
                            value={alumnusCount}
                            onValueChange={setAlumnusCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Interactors"
                            value={interactorsCount}
                            onValueChange={setInteractorsCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other Guests"
                            value={otherGuestsCount}
                            onValueChange={setOtherGuestsCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other Club Members"
                            value={otherClubMembersCount}
                            onValueChange={setOtherClubMembersCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other PIS"
                            value={otherPisCount}
                            onValueChange={setOtherPisCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other District Rotaractors"
                            value={otherDistrictRotaractorsCount}
                            onValueChange={setOtherDistrictRotaractorsCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isDisabled
                            type="number"
                            variant="underlined"
                            label="Total"
                            value={activeAttendees.size + guestAttendees.size + dcmAttendees.size
                                + Number(rotariansCount) + Number(alumnusCount) + Number(interactorsCount) + Number(otherGuestsCount) + Number(otherClubMembersCount)
                                + Number(otherDistrictRotaractorsCount) + Number(otherPisCount)}
                            placeholder="0"
                            className="max-w-md"
                        />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                        <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Supporting Documents</h2>
                        <div className="grid grid-cols-1 gap-4 items-center">
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Cover Image URL"
                                className="max-w-md"
                                isInvalid={isInvalidCoverImage}
                                color={isInvalidCoverImage ? "danger" : "success"}
                                errorMessage={isInvalidCoverImage && "Url is not of type 'https://drive.google.com/file/d/'. Please enter correct url"}
                                value={coverImageURL}
                                onValueChange={setCoverImageURL}/>
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Support Documents URL"
                                className="max-w-md"
                                value={supportDocumentsURL}
                                onValueChange={setSupportDocumentsURL}/>
                        </div>
                        {coverImageURL && <div className="grid grid-cols-1 gap-4 items-center">
                            <img
                                src={getImageFromDrive(coverImageURL)}
                                alt="Cover Photo"
                                className="reportsCoverImage"/>
                        </div>}
                    </div>

                    {checkIsProjectEdit() && <>
                        <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Social Media Links </h2>
                        {socialMediaLinks.map((socialMedia, index) => (
                            <div className="grid sm:grid-cols-12 gap-4 items-center">
                                <Select
                                    isRequired
                                    items={DD_SOCIAL_MEDIA_PLATFORMS}
                                    label="Social Media Platform"
                                    variant="underlined"
                                    className="sm:col-span-3"
                                    defaultSelectedKeys={[socialMedia.platformName]}
                                    onChange={e => addPlatformName(index, e)}
                                >
                                    {prjLevelListItems =>
                                        <SelectItem key={prjLevelListItems.value} value={prjLevelListItems.value}>
                                            {prjLevelListItems.label}
                                        </SelectItem>
                                    }
                                </Select>

                                <Input
                                    type="text"
                                    variant="underlined"
                                    label="Link"
                                    value={socialMedia.postURL}
                                    onChange={e => addPlatformUrl(index, e)}
                                    className="sm:col-span-8"
                                />
                                {socialMediaLinks.length > 1
                                    ? <Button isIconOnlytype="button" color="danger" variant="flat"
                                              className="w-auto col-span-1 sm:col-span-1 md:order-3"
                                              onClick={() => removeFormFieldsForSocialMediaLinks(index)}>
                                        <Minus fill="currentColor"
                                               size={28}/>
                                    </Button>
                                    : null}
                            </div>
                        ))}
                        <div className="mt-2 w-full flex justify-center">
                            <Button className="max-w-sm" color="primary" variant="ghost" startContent={
                                <Add fill="currentColor" size={28}/>
                            } onClick={addFieldsForSocialMediaLinks}>
                                Add more
                            </Button>
                        </div>
                    </>}
                    {isJointProject &&
                        <>
                            <h2 className="mt-6 text-lg text-gray-900 col-span-full">&nbsp;Joint Event Clubs</h2>
                            <Select
                                isRequired
                                selectionMode="multiple"
                                items={clubsList}
                                isLoading={fetchClubListStatus === "pending"}
                                isDisabled={clubsList.length === 0}
                                label="Select Clubs"
                                variant="underlined"
                                className="col-span-2 sm:col-span-4"
                                selectedKeys={jointProjectClubs}
                                onSelectionChange={setJointProjectClubs}>
                                {clubs => <SelectItem key={clubs.clubCode} value={clubs.clubCode}>
                                    {clubs.name}
                                </SelectItem>}
                            </Select>
                        </>
                    }
                </CardBody>
                <CardFooter>
                    <Button color="default" variant="bordered" className="mr-3"
                            onClick={() => clearTaskAndCloseProjectsScreen()}>
                        Cancel
                    </Button>

                    <Button color="success" variant="ghost" type="submit"
                            isLoading={newReportStatus === "pending" || editedReportStatus === "pending"}>
                        {isDraftReport ? 'Save as Draft' : 'Submit'}
                    </Button>
                </CardFooter>
            </form>
        </Card>
    );
}