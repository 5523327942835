import {useSelector} from "react-redux";
import {selectSelectedApplicant} from "../redux/slice/application57Slice";
import {useNavigate} from "react-router-dom";
import React, {useRef, useState} from "react";
import BundledEditor from "../../BundledEditor";

export default function IndividualApplications() {
    const individualsData = useSelector(selectSelectedApplicant);
    const navigate = useNavigate();
    const editorRef = useRef(null);

    if (null === individualsData) {
        navigate("/pranali/council57-applications");
    }

    function isOpenToOtherPositions() {
        if (individualsData.isOpenToOtherPositions) {
            return "Yes"
        } else {
            return "No";
        }
    }

    const [formData, setFormData] = useState(
            '<h2>Basic Details</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' +
            '   <table style="border-collapse: collapse; width: 100%; height: 100%;" border="1">' +
            '       <colgroup>' +
            '           <col style="width: 34%;">' +
            '           <col style="width: 33%;">' +
            '           <col style="width: 33%;">' +
            '       </colgroup>' +
            '       <tbody>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Applicant Name</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Email Id</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Contact Number</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.basicDetails.name + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.basicDetails.email + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.basicDetails.contact + '</td>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Date of birth</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Application Id</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Submitted at</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.basicDetails.dateOfBirth + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.applicationId + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + new Date(individualsData.timestamp.replaceAll("T", " ")).toLocaleString() + '</td>' +
            '       </tr>' +
            '       </tbody>' +
            '   </table>' +
            '</div><hr/>' +
            '<h2>Address</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' +
            '   <table style="border-collapse: collapse; width: 100%; height: 100%;" border="1">' +
            '       <colgroup>' +
            '           <col style="width: 65%;">' +
            '           <col style="width: 35%;">' +
            '       </colgroup>' +
            '       <tbody>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Flat/House no. + Floor + Complex/Apartment Name</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Street Name</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.address.addressLine1 + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.address.street + '</td>' +
            '       </tr>' +
            '       </tbody>' +
            '   </table>' +
            '   <table style="border-collapse: collapse; width: 100%; height: 100%;" border="1">' +
            '       <colgroup>' +
            '           <col style="width: 34%;">' +
            '           <col style="width: 33%;">' +
            '           <col style="width: 33%;">' +
            '       </colgroup>' +
            '       <tbody>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Landmark</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">City</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Zipcode</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.address.landmark + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.address.city + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.address.zipcode + '</td>' +
            '       </tr>' +
            '       </tbody>' +
            '   </table>' +
            '</div><hr/>' +
            '<h2>Profession Details</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' +
            '   <table style="border-collapse: collapse; width: 100%; height: 100%;" border="1">' +
            '       <colgroup>' +
            '           <col style="width: 34%;">' +
            '           <col style="width: 33%;">' +
            '           <col style="width: 33%;">' +
            '       </colgroup>' +
            '       <tbody>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Educational Qualification</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Occupation as on 1st July 2024</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Employer/Business name</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.professionDetails.educationalQualification + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.professionDetails.occupation + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.professionDetails.workPlace + '</td>' +
            '       </tr>' +
            '       </tbody>' +
            '   </table>' +
            '</div><hr/>' +
            '<h2>Rotaract Details</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' +
            '   <table style="border-collapse: collapse; width: 100%; height: 100%;" border="1">' +
            '       <colgroup>' +
            '           <col style="width: 25%;">' +
            '           <col style="width: 50%;">' +
            '           <col style="width: 10%;">' +
            '           <col style="width: 15%;">' +
            '       </colgroup>' +
            '       <tbody>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Rotaraction started on</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Home Club</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Zone</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Pranali Id</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.rotaractDetails.yearOfJoining + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.rotaractDetails.homeClub + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.rotaractDetails.zone + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.rotaractDetails.pranaliId + '</td>' +
            '       </tr>' +
            '       </tbody>' +
            '   </table>' +
            '</div><hr/>' +
            '<h2>Positions held till date</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.positionsTillDate + '</div><hr/>' +
            '<h2>Significant contribution to the movement</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.significantContribution + '</div><hr/>' +
            '<h2>Any achievement in club/District till date\n</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.achievements + '</div><hr/>' +
            '<h2>Top Council 57 Preferences</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' +
            '   <table style="border-collapse: collapse; width: 100%; height: 100%;" border="1">' +
            '       <colgroup>' +
            '           <col style="width: 34%;">' +
            '           <col style="width: 33%;">' +
            '           <col style="width: 33%;">' +
            '       </colgroup>' +
            '       <tbody>' +
            '       <tr style="height: 19.6px;">' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Preference 1</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Preference 2</th>' +
            '           <th style="height: 19.6px; text-align: left;border-style: hidden; font-size: 14pt;">Preference 2</th>' +
            '       </tr>' +
            '       <tr style="height: 19.6px;">' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.preferences[0] + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.preferences[1] + '</td>' +
            '           <td style="height: 19.6px; border-style: hidden;">' + individualsData.preferences[2] + '</td>' +
            '       </tr>' +
            '       </tbody>' +
            '   </table>' +
            '</div><hr/>' +
            '<h2>Reason for applying for all three positions</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.preferenceReasons + '</div><hr/>' +
            '<h2>Is open to any Council position other than the above preferences?</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + isOpenToOtherPositions() + '</div><hr/>' +
            '<h2>Why should you be selected in Council 57?</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.whyCouncil + '</div><hr/>' +
            '<h2>Applicant\'s Strengths</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.strengths + '</div><hr/>' +
            '<h2>Applicant\'s Traits he want to work upon during tenure as council member</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.traits + '</div><hr/>' +
            '<h2>Any intercity/overseas academic or professional commitments that could come up mid-year?</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.commitmentsMidYear + '</div><hr/>' +
            '<h2>In your Rotaract journey, where do you see yourself 2 years down the line?</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.seeUrSelfIn2Years + '</div><hr/>' +
            '<h2>Any suggestions for the district functioning / district events?</h2>' +
            '<div style="padding-left: 20px; margin-bottom: 20px;">' + individualsData.suggestionsForEvents + '</div><hr/>'
        );

    return (
        <BundledEditor
            name="formData"
            value={formData}
            // disabled={true}
            init={{
                height: 2500,
                menubar: false,
                paste_data_images: false,
                invalid_elements: 'img,video,picture',
                plugins: ["wordcount"],
                toolbar: 'print',
                content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
        />
    );
}