import {ToastContainer} from "react-toastify";
import {Button} from "@nextui-org/react";
import {Add, Refresh} from "../../Icons/SystemIcons";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {useNavigate} from "react-router-dom";
import {getClubListFetchStatus} from "../../redux/slice/clubDataSlice";
import ClubTable from "./ClubTable";
import {getClubsList} from "../../../services/ClubServices";

export default function ClubListInUserDistrict() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clubListFetchStatus = useSelector(getClubListFetchStatus);

    useEffect(() => {
        if (clubListFetchStatus === 'idle')
            getClubsList(dispatch, user);
    }, []);

    return (
        <div className="flex flex-col gap-4 sm:gap-8">
            <ToastContainer/>
            <div className="flex justify-around gap-4 sm:gap-8 items-center">
                <div className="relative">
                    <h1 className="text-2xl sm:text-3xl font-medium">Club in {user.districtId}</h1>
                </div>

                <div className="flex flex-col">
                    <div className="flex justify-end items-center">
                        <Button className="hidden sm:flex mr-2" type="button" color="success" variant="ghost"
                                startContent={<Add fill="currentColor" size={28}/>}
                                onClick={() => {
                                    // dispatch(setSelectedReport({
                                    //     report: null,
                                    //     reportType: "Project"
                                    // }));
                                    // dispatch(setNewReportStatus('idle'))
                                    // dispatch(setSelectedReportStatus('idle'))
                                    navigate("/pranali/clubs-3142/onboard-new")
                                }}>
                            New Club
                        </Button>
                        <Button className="mr-2 sm:hidden" type="button" isIconOnly color="success" variant="ghost"
                            // onClick={() => navigate("/pranali/projects/new-report")}
                        >
                            <Add fill="currentColor" size={28}/>
                        </Button>
                        <Button type="button" isIconOnly color="default" variant="ghost"
                                onClick={() => getClubsList(dispatch, user, false)}>
                            <Refresh fill="currentColor" size={28}/>
                        </Button>
                    </div>
                </div>
            </div>
            <ClubTable/>
        </div>
    );
}