import BundledEditor from "../../BundledEditor";
import React from "react";

export function applicationRTESection(positionsTillDate, handleUpdate, handleBeforeAddUndo,
                                      title, isRequired, description) {
    return (
        <div>
            <h2 className="mt-10 text-lg text-gray-900">&nbsp;{title}
                {isRequired === true && <span className="text-danger"> *</span>}
            </h2>
            {description !== null && <p className="mb-2 italic text-sm text-gray-600">&nbsp;{description}</p>}
            <BundledEditor
                name="groundWork"
                value={positionsTillDate}
                onEditorChange={handleUpdate}
                onBeforeAddUndo={handleBeforeAddUndo}
                // onPaste={handlePasteForGroundWork}
            />
        </div>
    )
}