import ApiEndpoint from "./ApiEndpoint";
import axios from "axios";
import {throwErrorToast} from "../components/admin/aaras/NewNominationForProjects";
import {
    setSelectedFinanceRecord,
    setSelectedFinanceRecordStatus, setUpdateRecordStatus, updateRecords
} from "../components/redux/slice/financeSlice";
import {toast} from "react-toastify";
import {toastOptions} from "../constants/WebPageConstants";
import React from "react";
import {getFinanceRecords} from "../components/admin/Finances/Finances";
import {setUser} from "../components/redux/slice/userSlice";
import {getClubDataById} from "./ClubServices";

export function createRazorpayOrder(value, user) {
    const apiEndPoint = new ApiEndpoint();
    if (null !== value && value > 0) {
        return axios(apiEndPoint.createOrder(user, value)).then(res => {
            return res;
        }).catch(e => throwErrorToast(e))
    } else {
        toast.error(<p className="text-medium"> Order cannot be created for 0 or null amount.</p>, toastOptions)
    }
}

export function addMoneyToClubWallet(reqBody, user, dispatch) {
    const apiEndPoint = new ApiEndpoint();

    axios(apiEndPoint.capturePayment(user, reqBody)).then(res => {
        if (res.status === 201)
            toast.success(<p className="text-medium">Amount to added in wallet</p>, toastOptions);
        getClubDataById(dispatch, user, null)
        getFinanceRecords(dispatch, user);
        return res;
    }).catch(e => {
        throwErrorToast(e);
        getFinanceRecords(dispatch, user);
    });
}

export function payAdminDues(homeClubData, user, dispatch) {
    const apiEndPoint = new ApiEndpoint();

    axios(apiEndPoint.payAdminDues(user)).then(res => {
        if (res.status === 204) {
            let tempUserData = JSON.parse(JSON.stringify(user));
            tempUserData.isAdminDuesPaid = true;
            dispatch(setUser(tempUserData));

            getClubDataById(dispatch, user, null);
            toast.success(<p className="text-medium">Admin dues paid successfully</p>, toastOptions);
            getFinanceRecords(dispatch, user);
        }
        return res;
    }).catch(e => throwErrorToast(e));
}

export function getFinancialRecordsByTransactionId(transactionId, user, dispatch) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedFinanceRecordStatus("pending"));
    dispatch(setSelectedFinanceRecord(null));

    axios(apiEndPoint.getFinancialRecordsByTransactionId(user, transactionId)).then(res => {
        if (res.status === 200) {
            dispatch(setSelectedFinanceRecord(res.data));
            dispatch(setSelectedFinanceRecordStatus("complete"));
        }
        return res;
    }).catch(e => throwErrorToast(e));
}

export function updateOrderStatus(transactionId, paymentStatus, comments, user, dispatch, financeRecords) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setUpdateRecordStatus("pending"));

    let financeUpdateRequest = {
        paymentStatus: paymentStatus,
        updateMessage: comments
    }

    let recordIndex = financeRecords.findIndex(data => data["transactionId"] === transactionId)
    let tempSelectedItem = JSON.parse(JSON.stringify(financeRecords[recordIndex]));
    tempSelectedItem.paymentStatus = paymentStatus;

    return axios(apiEndPoint.updateOrderStatus(user, transactionId, financeUpdateRequest)).then(res => {
        if (res.status === 204) {
            dispatch(setUpdateRecordStatus("complete"));
            dispatch(updateRecords({recordIndex: recordIndex, updatedRecord: tempSelectedItem}));
            dispatch(setSelectedFinanceRecord(tempSelectedItem));
            toast.success(<p className="text-medium">Transaction updated successfully</p>, toastOptions);
        }
        return res;
    }).catch(e => {
        throwErrorToast(e);
        dispatch(setUpdateRecordStatus("complete"));
    });
}